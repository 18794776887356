/* 
  A convenient hook for completing some behavior (in handler function) 
  when a click is detected outside of a component (which has provided ref)
*/
import { useEffect } from 'react'

export default function useOnClickOutside(ref: any, handler: Function) {
  useEffect(() => {
    const listener = (e: Event) => {
      // do nothing if clicking the ref's element or descendent elements
      if (!ref.current || ref.current.contains(e.target)) {
        return
      }

      // otherwise, carry out desired code for clicking outside of element
      handler(e)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    // clean up listeners when component unmounts
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
