import React from 'react'
import { renderToString } from 'react-dom/server'
import { Helmet } from 'react-helmet'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Fuse from 'fuse.js'
import unified from 'unified'
import markdown from 'remark-parse'
import rehype from 'remark-rehype'
import raw from 'rehype-raw'
import stringify from 'rehype-stringify'
import classNames from 'classnames'
import useOnClickOutside from '../components/hooks/useOnClickOutside'

import Layout from '../components/Layout/Layout'
// import Footer from "../components/Layout/Footer/Footer"

import styles from '../assets/styles/blog.module.scss'

const Blog = ({ location }) => {
  const data = useStaticQuery(graphql`
    query blogQuery {
      allAirtable(
        filter: {
          data: { Publishing_Status: { eq: "Publish" } }
          table: { eq: "Blog" }
        }
        sort: { order: DESC, fields: data___Date }
      ) {
        edges {
          node {
            id
            data {
              Blog_Text
              Excerpt
              Cover_Image {
                localFiles {
                  publicURL
                }
              }
              Author
              Category
              Date
              Title
              URL
              Text_Link
              Tags
            }
          }
        }
      }
    }
  `)
  const { edges: posts } = data.allAirtable

  const [searchString, setSearchString] = React.useState('')
  const [tagSearchString, setTagSearchString] = React.useState('')
  const [tags, setTags] = React.useState(location.state?.tags || [])
  const [tagResults, setTagResults] = React.useState([])

  // unified processor for parsing MD and HTML from Airtable
  const processor = unified()
    .use(markdown)
    .use(rehype, { allowDangerousHtml: true })
    .use(raw)
    .use(stringify)

  // create ref for tag search results
  const searchRef = React.useRef()

  // use hook to detect clicks outside of tag search results
  // and clear search string/hide search results when click is detected
  useOnClickOutside(searchRef, () => setTagSearchString(''))

  const createBlogPostElements = posts =>
    posts
      .filter(post => post.node.data.Title.length > 0)
      .map(({ node: post }) => (
        <div className={styles.post} key={post.id}>
          <h1>
            <Link to={post.data.URL}>{post.data.Title}</Link>
          </h1>
          {post.data.Date && (
            <h2>
              {new Date(post.data.Date).toLocaleString('default', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
              })}
            </h2>
          )}
          {
            /* Hide author if there is none */
            post.data.Author !== '' && (
              <h3
                dangerouslySetInnerHTML={{
                  __html: post.data.Author,
                }}
              />
            )
          }
          <div className={styles.previewContent}>
            <section
              className={styles.excerptAndImg}
              dangerouslySetInnerHTML={{
                __html:
                  renderToString(
                    post.data.URL !== '#' && post.data.Cover_Image !== null && (
                      <Link to={post.data.URL}>
                        <img
                          src={post.data.Cover_Image.localFiles[0].publicURL}
                          alt={post.data.Title}
                        />
                      </Link>
                    )
                  ) +
                  processor.processSync(post.data.Excerpt) +
                  renderToString(
                    post.data.URL !== '#' && post.data.Text_Link !== null && (
                      <Link to={post.data.URL}>{post.data.Text_Link}</Link>
                    )
                  ),
              }}
            ></section>
            <section className={styles.tags}>
              {post.data.Tags.map(tag => (
                <div
                  key={tag}
                  className={styles.tag}
                  onClick={() => {
                    if (!tags.includes(tag)) {
                      setTags([...tags, tag])
                      window.scrollTo(0, 0)
                    }
                  }}
                >
                  {tag}
                </div>
              ))}
            </section>
            <div />
          </div>
        </div>
      ))

  const createRecentPostsElements = posts =>
    posts
      .filter(({ node: post }) => post.data.Title.length > 0)
      .map(({ node: post }) => (
        <div className={styles.post} key={post.id}>
          <Link to={post.data.URL}>{post.data.Title}</Link>
          <p>{post.data.Date}</p>
        </div>
      ))

  // get list of all tags
  const allTags = []
  posts.forEach(post => {
    post.node.data.Tags.forEach(tag => {
      if (!allTags.includes(tag)) {
        allTags.push(tag)
      }
    })
  })

  // repurposing this filtering for use with tags
  let filteredPosts = posts
  if (tags.length !== 0) {
    filteredPosts = posts.filter(post => {
      let postHasMatchingTag = false
      // iterate through selected tags and include post if it has at least one
      tags.forEach(tag => {
        if (post.node.data.Tags.includes(tag)) postHasMatchingTag = true
      })
      return postHasMatchingTag
    })
  }

  const searchOptions = {
    isCaseSensitive: false,
    distance: 100,
    shouldSort: true,
    keys: [
      'node.data.Blog_Text',
      'node.data.Title',
      'node.data.Author',
      'node.data.Tags',
    ],
  }

  const fuse = new Fuse(posts, searchOptions)

  let searchedPosts = filteredPosts
  if (searchString !== '') {
    searchedPosts = fuse.search(searchString).map(result => result.item)

    if (searchedPosts.length === 0) {
      searchedPosts = [
        {
          node: {
            id: '0',
            data: {
              Blog_Text: '',
              Excerpt: 'Please try a different search.',
              Cover_Image: {
                localFiles: [
                  {
                    publicURL: undefined,
                  },
                ],
              },
              Author: '',
              Category: 'Report',
              Date: undefined,
              Title: 'No results found for that search.',
              URL: '#',
              Tags: [],
            },
          },
        },
      ]
    }
  }

  React.useEffect(() => {
    setTagResults(
      allTags.filter(tag =>
        tag.toLowerCase().includes(tagSearchString.toLowerCase())
      )
    )
  }, [tagSearchString])

  return (
    <Layout page="Activities">
      <Helmet
        title={`HIV Policy Lab Activities`}
        meta={[
          {
            name: 'description',
            content: `Activities from the HIV Policy Lab Team.`,
          },
        ]}
      />

      <header className={styles.header}>
        <h1>ACTIVITIES</h1>
        <Link to="/contact/">Contact us</Link>
      </header>

      <section className={styles.main}>
        <section className={styles.blogPosts}>
          <div className={classNames(styles.selectedTags)}>
            {tags.length !== 0 && (
              <div className={styles.tagsContainer}>
                <div className={styles.tagsHeader}>
                  <div className={styles.headerLeft}>
                    <h2>Selected tags:</h2>
                    <button onClick={() => setTags([])}>Clear all</button>
                  </div>
                  <div className={styles.headerRight} ref={searchRef}>
                    <input
                      className={styles.searchbar}
                      id="tag_search"
                      type="text"
                      name="tag_search"
                      value={tagSearchString}
                      onChange={e => setTagSearchString(e.target.value)}
                      placeholder="search tags"
                    />
                    {tagSearchString !== '' && (
                      <div className={styles.searchResults}>
                        {tagResults.map(tag => (
                          <div
                            key={tag}
                            className={styles.tagResult}
                            onClick={() => {
                              if (!tags.includes(tag)) {
                                setTags([...tags, tag])
                                window.scrollTo(0, 0)
                              }
                            }}
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.tags}>
                  {tags.map(tag => (
                    <div
                      key={tag}
                      className={styles.tag}
                      onClick={() => {
                        setTags(tags.filter(t => t !== tag))
                      }}
                    >
                      {tag}
                      <span className={'material-icons-round'} role="img">
                        close
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          {createBlogPostElements(searchedPosts)}
        </section>

        <section className={styles.recentPosts}>
          <div className={styles.form}>
            <label htmlFor="search">Search</label>
            <input
              className={styles.searchbar}
              id="search"
              type="text"
              name="search"
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
              placeholder="search activities"
            />
          </div>
          <h1>Recent Activities</h1>
          {createRecentPostsElements(posts)}
        </section>
      </section>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query IndexQuery {
//     allAirtable(
//       filter: { data: { publishing_status: { eq: "Publish" } } }
//       sort: { order: DESC, fields: data___Date }
//     ) {
//       edges {
//         node {
//           id
//           data {
//             Blog_Text
//             Images {
//               url
//             }
//             author
//             Category
//             Date
//             title
//             slug
//           }
//         }
//       }
//     }
//   }
// `

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
//       edges {
//         node {
//           excerpt(pruneLength: 500)
//           id
//           frontmatter {
//             title
//             date(formatString: "dddd, MMMM DD, YYYY")
//             path
//             category
//             author
//           }
//         }
//       }
//     }
//   }
// `

export default Blog
